<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'name'"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :selectFields="['id', 'name']"
    >
        <template #columns>
            <Column field="name" :header="$t('overview.name')"></Column>
        </template>

        <template #form>
            <ColourForm ref="ItemForm" :itemData="item" />
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import ColourForm from '@/components/colour/ColourForm.vue';
import colourService from '@/services/ColourService';
import store from '@/store/user';

export default {
    components: {
        Overview,
        Column,
        ColourForm
    },
    data() {
        return {
            item: {},
            itemService: colourService,
            itemFormRef: null
        }
    },
    mounted() {
        if(!store.getters.getCompanyHq){
            window.location.href = '/';
            return;
        }
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>